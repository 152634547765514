import { Injectable } from '@angular/core'
import { ResolveEnd, Router } from '@angular/router'
import { BehaviorSubject, delay, filter, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private readonly navigationResolveEnd$: Observable<ResolveEnd> =
    this.router.events.pipe(filter(isNavigationResolveEnd))

  private useLegacyLayoutSubject$ = new BehaviorSubject(false)
  public readonly useLegacyLayout$ = this.useLegacyLayoutSubject$
    .asObservable()
    .pipe(delay(0)) // this fixes ExpressionChangedAfterItHasBeenCheckedError in Angular 15

  private allowContentOverflowSubject$ = new BehaviorSubject(true)
  public readonly allowContentOverflow$ =
    this.allowContentOverflowSubject$.asObservable()

  constructor(private router: Router) {
    this.navigationResolveEnd$.subscribe(() => {
      this.unsetUseLegacyLayout()
      this.setAllowContentOverflow()
    })
  }

  public setUseLegacyLayout() {
    this.useLegacyLayoutSubject$.next(true)
  }

  private unsetUseLegacyLayout() {
    this.useLegacyLayoutSubject$.next(false)
  }

  public setAllowContentOverflow() {
    this.allowContentOverflowSubject$.next(true)
  }

  public unsetAllowContentOverflow() {
    this.allowContentOverflowSubject$.next(false)
  }
}

function isNavigationResolveEnd(event: any): event is ResolveEnd {
  return event instanceof ResolveEnd
}
